import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class Employment extends React.Component {
	render() {
		const siteTitle = 'Employment Solutions'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<Container className="py-5">
					<Row>
						<Col id="employment" className="my-4">
							<h4 className="m-0 mb-2">1. Hiring</h4>
							<p>
								Hiring a new employee can take a lot of time - time that, as a
								business owner, you probably don't have. If you have positions
								that needs to be filled (and growing companies often do), you
								need someone who can dedicate their time to assist you with that
								process of hiring correctly and efficiently. That means
								recruiting, sifting through resumes, screening applications,
								setting up interviews, selecting candidates and more. Hiring new
								employees is incredibly important - you want to find the best
								talent and the best fit the first time around, so you don't end
								up wasting time or money. And to do that, you need help. We can
								help. Another reason you need a human resources consulting
								Partner? We know the ins and outs of employment best practice.
								All it takes is one mistake when hiring or terminating an
								employee, and you could get slapped with a lawsuit. If you're
								not familiar with the many different employment laws, you could
								be putting yourself, your business and your reputation at risk.
								To avoid lawsuites and internal conflicts, consult with an HR
								professional who knows how to remain complaint with all
								different state and federal rules and regulations.
							</p>
							<ul className="employment-list">
								<li>
									<i className="fa fa-check" />
									&nbsp;Talent Search - Full Time, Contract or Contract to hire
								</li>
								<li>
									<i className="fa fa-check" />
									&nbsp;Coaching & Mentoring
								</li>
								<li>
									<i className="fa fa-check" />
									&nbsp;Defining and Improving Culture
								</li>
								<li>
									<i className="fa fa-check" />
									&nbsp;Retain and Grow a dynamic team
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</Layout>
		)
	}
}

export default Employment

export const EmploymentPageQuery = graphql`
	query EmploymentPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
